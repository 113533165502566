import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33')
];

export const server_loads = [0,2,3,6,4,5,8];

export const dictionary = {
		"/": [~9],
		"/about-us": [10],
		"/admin": [11,[2]],
		"/admin/orders": [~12,[2]],
		"/build-a-book": [13],
		"/character": [14,[3]],
		"/character/create": [~15,[3]],
		"/design": [16],
		"/design/create": [18],
		"/design/p/[slug]": [19,[6]],
		"/design/p/[slug]/edit": [~20,[6]],
		"/design/p/[slug]/print": [21,[6]],
		"/design/[slug]/edit": [~17,[4,5]],
		"/landing": [22],
		"/login": [~23],
		"/payments/success": [24],
		"/policies/cookies": [25,[7]],
		"/policies/privacy": [26,[7]],
		"/policies/terms": [27,[7]],
		"/pricing": [28],
		"/project/[slug]": [29],
		"/settings": [30,[8]],
		"/settings/profile": [31,[8]],
		"/subscribe": [32],
		"/user": [33]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';